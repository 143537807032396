@tailwind base;
@tailwind components;
@tailwind utilities;



.checkbox:checked + .block {
    @apply bg-blue-500;
  }
  
  .checkbox:checked + .block .dot {
    @apply transform translate-x-full;
  }
  
  .dot {
    transition: transform 0.3s ease-in-out;
  }